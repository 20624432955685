import React from 'react';
import { Container } from 'reactstrap';
import styles from './PageHeading.module.css';

const PageHeading = ({ children }) => (
  <section className={styles.headerContainerRow}>
    <Container>
      <h1 className="display-4">{children}</h1>
    </Container>
  </section>
);
export default PageHeading;
