import React, { Component } from 'react';
import { Container, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../../../components/Layout';
import PageHeading from '../../../components/PageHeading/PageHeading';
import ResourceTable from '../../../components/ResourceTable/ResourceTable';

class Curricula extends Component {
  render() {
    const { data } = this.props;
    const { edges: resources } = data.allCurriculaListJson;
    const mappedResources = resources.map(resource => resource.node);
    return (
      <Layout>
        <Helmet
          title="VEYM | Curricula"
          meta={[
            {
              name: 'description',
              content: 'Chương Trình Thăng Tiến - Curricula for all levels',
            },
          ]}
        />
        <PageHeading>Chương Trình Thăng Tiến</PageHeading>
        <Container>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to="/resources">Resources</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Curricula</BreadcrumbItem>
          </Breadcrumb>
        </Container>
        <br />
        <Container>
          <ResourceTable resources={mappedResources} striped bordered />
        </Container>
      </Layout>
    );
  }
}

export default Curricula;

export const pageQuery = graphql`
  query CurriculaQuery {
    allCurriculaListJson {
      edges {
        node {
          name
          type
          links {
            fileType
            fileLink
          }
          categoryStyle {
            background
            border
          }
          categoryTextStyle {
            color
          }
          updated
        }
      }
    }
  }
`;

export const frontmatter = {
  title: 'Curricula',
  category: 'resources',
  path: '/resources/curricula',
  description: 'Curricula',
  tags:
    'resource, chuong trinh thang tien, cttt, curriculum, au nhi, thieu nhi, nghia si, hiep si, minimum standards,  ',
};
