import React, { Fragment } from 'react';
import { Table, Button, UncontrolledTooltip } from 'reactstrap';
import moment from 'moment';
import MediaQuery from 'react-responsive';
import styles from './ResourceTable.module.css';

const ResourceTable = ({ resources, striped, bordered, hover }) => {
  const threeMonthsAgo = moment().subtract(6, 'months');

  return (
    <div className={styles.resourceTable}>
      <Table striped={striped} bordered={bordered} hover={hover}>
        <tbody>
          {resources.map(
            (
              { type, categoryStyle, categoryTextStyle, name, links, updated },
              index
            ) => {
              if (type === 'category') {
                return (
                  <tr key={name}>
                    <td style={categoryStyle} colSpan="2 ">
                      <strong style={categoryTextStyle}>{name}</strong>
                    </td>
                  </tr>
                );
              }

              return (
                <Fragment key={`${type}-${name}`}>
                  <tr>
                    <td
                      className={styles.nameColumn}
                      id={`${name
                        .replace(/\s+/g, '')
                        .replace(/[0-9]/g, '')
                        .replace(/\W/g, '')}-${index}`}
                    >
                      {/* <strong>{name}</strong> */}
                      <div>
                        {name}
                        &nbsp;&nbsp;&nbsp;
                        {updated !== null &&
                          updated !== undefined &&
                          moment(updated).isAfter(threeMonthsAgo) && (
                            <i className={`fa fa-circle ${styles.recent3}`} />
                          )}
                        &nbsp;
                        <MediaQuery query="(min-width: 769px)">
                          {updated !== null && updated !== undefined && (
                            <span className={styles.updatedText}>
                              - Updated {moment(updated).format('MM/YYYY')}
                            </span>
                          )}
                        </MediaQuery>
                      </div>
                    </td>
                    <td className={styles.fileColumn}>
                      {links.map(({ fileType, fileLink }) => (
                        <Fragment key={`file-${fileLink.slice(-8)}`}>
                          {/* <Button
                            size="sm"
                            color="secondary"
                            onClick={() => window.open(fileLink, '_blank')}
                          >
                            {fileType}
                          </Button> */}
                          <a
                            href={fileLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Button size="sm" color="secondary">
                              {fileType}
                            </Button>
                          </a>
                          &nbsp;
                        </Fragment>
                      ))}
                    </td>
                  </tr>
                  <MediaQuery query="(max-width: 768px)">
                    {updated !== null && (
                      <UncontrolledTooltip
                        placement="bottom"
                        target={`${name
                          .replace(/\s+/g, '')
                          .replace(/[0-9]/g, '')
                          .replace(/\W/g, '')}-${index}`}
                      >
                        Updated {moment(updated).format('MM/YYYY')}
                      </UncontrolledTooltip>
                    )}
                  </MediaQuery>
                </Fragment>
              );
            }
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default ResourceTable;
